import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ToastContainer, toast } from 'react-toastify'

import '../styles/room.scss'
import { nanoid } from 'nanoid'

const Room = ({ game, sendMessage, thisPlayer, ws, setWs }) => {
  let thisRound = game?.rounds[game.rounds.length - 1]
  const [isAnswered, setIsAnswered] = useState(false)
  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      answer: '',
    },
    mode: 'onChange',
  })
  let playerAvatar = ''
  let sentence = thisRound.sentence
  let playerWord = {}

  const formSchema = {
    answer: {
      required: {
        value: true,
        message: 'Kullanıcı adı zorunludur.',
      },

      minLength: {
        value: 2,
        message: 'En az 2 harf giriniz.',
      },
      transform: (value) => value.trim(),
    },
  }

  const handleAnswer = (event) => {
    event.preventDefault()
    let thisAnswer = getValues('answer')
    // console.log(thisAnswer)
    // console.log('About to send Answer for thisPlayer.name: ', thisPlayer.name)
    sendMessage({
      event: 'answer',
      player: { name: thisPlayer.name, answer: thisAnswer },
    })
    setIsAnswered(true)
  }

  //check if answer is not null or "" with useEffect if so set isAnswered to true
  // useEffect(() => {
  //   if (thisPlayer.answer !== null || thisPlayer.answer !== '') {
  //     isAnswered = true
  //     playerAvatar = 'none'
  //   }
  // }, [getValues('answer')])

  //Key press handler for inputs
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleAnswer()
      }
      document.addEventListener('keypress', handleKeyPress)

      return () => {
        document.removeEventListener('keypress', handleKeyPress)
      }
    }
  }, [])

  return (
    <div className="room">
      <div className="room__main-container">
        <div className="header">
          <div className="info-container">
            <h3 className="room-info">
              <p>Room: {game.roomCode || '3ASX'}</p>
              <p>Host: {game.host.name}</p>
            </h3>
            <div className="player_info">
              <p className="player_name">{thisPlayer.name}</p>
              <img className="player_img" src={thisPlayer.avatar} />
            </div>
          </div>
        </div>
        <div className="sentence-container">
          <div className="sentence">
            {sentence.map((word, i) => {
              // console.log(word)
              if (word.type === 'text') {
                if (
                  !(
                    word.value.trim().length == 1 &&
                    word.value.trim().match(/\p{P}/u)
                  )
                ) {
                  return (
                    <span key={i} className="player_text">
                      {word.value}
                    </span>
                  )
                }
              } else if (word.type === 'question') {
                if (!word.player) {
                  const backgroundImage = `url(https://api.dicebear.com/6.x/adventurer-neutral/svg?seed=${nanoid(
                    10
                  )}&radius=50)`
                  return (
                    <div key={i} className="room__player-punctuation-container">
                      <div className="room__player">
                        <input
                          type="text"
                          style={{ backgroundImage: backgroundImage }}
                          disabled
                        />
                        <div className="room__player-text--below">
                          <p>{word.clue}</p>
                        </div>
                      </div>
                      {sentence[i + 1].type === 'text' &&
                        sentence[i + 1].value.trim().length == 1 && (
                          <span className="player_text">
                            {sentence[i + 1].value}
                          </span>
                        )}
                    </div>
                  )
                } else if (word.player.name === thisPlayer.name) {
                  playerWord = word
                  playerAvatar = `url(${word.player.avatar})`

                  return (
                    <div key={i} className="room__player-punctuation-container">
                      <ToastContainer></ToastContainer>

                      <div className="room__player">
                        <input
                          id="avatar"
                          type="text"
                          style={{ backgroundImage: playerAvatar }}
                          {...register('answer')}
                          disabled
                        />
                        <div className="room__player-text--below">
                          <p>{word.clue}</p>
                        </div>
                      </div>
                      {sentence[i + 1].type === 'text' &&
                        sentence[i + 1].value.trim().length == 1 && (
                          <span className="player_text">
                            {sentence[i + 1].value}
                          </span>
                        )}
                    </div>
                  )
                } else {
                  playerAvatar = `url(${word.player.avatar})`
                  return (
                    <div key={i} className="room__player-punctuation-container">
                      <div className="room__player">
                        <input
                          type="text"
                          style={{ backgroundImage: playerAvatar }}
                          disabled
                        />
                        <div className="room__player-text--below">
                          <p>{word.clue}</p>
                        </div>
                      </div>
                      {sentence[i + 1].type === 'text' &&
                        sentence[i + 1].value.trim().length == 1 && (
                          <span className="player_text">
                            {sentence[i + 1].value}
                          </span>
                        )}
                    </div>
                  )
                }
              }
            })}
          </div>
        </div>

        {!game.rounds[game.rounds.length - 1].players.some(
          (player) => player.name === thisPlayer.name
        ) ? (
          <p className="room__waiting-alert">Waiting for a new round...</p>
        ) : (
          <div className="room__bottom">
            <form
              className="room__bottom-container"
              disabled={isAnswered}
              onSubmit={handleAnswer}
            >
              <div className="room__input-container--left">
                <input
                  className="room__input-bottom"
                  type="text"
                  {...register('answer', formSchema.answer)}
                  placeholder={`Create a ${playerWord?.clue?.toLowerCase()} for '${
                    playerWord.value
                  }'.`}
                  autoFocus
                  disabled={isAnswered}
                />
              </div>
              <div className="room__input-container--right">
                <button
                  className="room__button"
                  disabled={isAnswered || !isValid}
                  style={{
                    pointerEvents: isAnswered && 'none',
                  }}
                >
                  {isAnswered ? 'Answered' : 'Submit'}
                </button>
              </div>
            </form>
            <div className="room__instructions">
              {!isAnswered ? (
                <p>
                  For example: <span>{playerWord.eg}</span>
                </p>
              ) : (
                <p>Waiting for other players to answer.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Room
