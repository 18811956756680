import React, { useState } from 'react'
import '../styles/welcomeModal.css'

const WelcomeModal = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <div
        id="button"
        className="container mx-auto flex justify-center items-center px-4 md:px-10 pt-0 pb-14"
      >
        <button
          onClick={openModal}
          className="py-2 px-7 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white rounded-3xl  border-2"
        >
          How to Play
        </button>
      </div>
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-content">
              <div className="flex w-full h-auto justify-end items-end">
                <div
                  onClick={closeModal}
                  className="flex w-1/12 h-auto justify-center cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </div>
              </div>
              <div className="flex w-full h-auto justify-center items-center">
                <div className=" text-gray-600 flex w-full h-auto py-3 justify-center items-center text-2xl font-bold">
                  Welcome!
                </div>
              </div>
              <div className="flex w-full h-auto py-10 px-4   rounded text-center text-gray-500">
              Fill in the blanks in the upcoming story and help it come to life. IN this multiplayer game, each player is tasked to fill in a part of the story. No one knows what other players' task, so make sure to get creative with your part.{' '}
              </div>

              <div className="mt-12 md:mt-14 w-full flex justify-center">
                <button
                  onClick={closeModal}
                  className=" w-full sm:w-auto  text-base font-medium  py-5 px-14  rounded-3xl border-2 border-white focus:ring-2 focus:ring-offset-2  hover:text-white dark:hover:text-white "
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WelcomeModal
