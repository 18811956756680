import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCrown } from '@fortawesome/free-solid-svg-icons'
import '../styles/lobby.css'
// import './counter.scss'

const Lobby = ({
  game,
  sendMessage,
  thisPlayer,
  setThisPlayer,
  ws,
  setWs,
  connectWebSocket,
  isGameStateRoom,
}) => {
  const [newRoundClicked, setNewRoundClicked] = useState(false)
  const [countdown, setCountdown] = useState(5)

  // check if THIS player is admin
  let isThisPlayerAdmin = game.host.name === thisPlayer.name

  const handleReady = () => {
    if (thisPlayer.ready == true) {
      //console.log('About to send NotReady for thisPlayer.name: ' + thisPlayer.name)
      setThisPlayer({ ...thisPlayer, ready: false })
      sendMessage({
        event: 'playerNotReady',
        player: { name: thisPlayer.name },
      })
    } else {
      //console.log('About to send Ready for thisPlayer.name: ' + thisPlayer.name)
      setThisPlayer({ ...thisPlayer, ready: true })
      sendMessage({ event: 'playerReady', player: { name: thisPlayer.name } })
    }
  }

  const handleStart = () => {
    //console.log('About to send Start for thisPlayer.name: ' + thisPlayer.name)
    sendMessage({ event: 'newRound', player: { name: thisPlayer.name } })
    setNewRoundClicked(true)
  }
  useEffect(() => {
    if (countdown > 0 && isGameStateRoom) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(timer)
    } else {
      setCountdown(5)
    }
  }, [isGameStateRoom])

  //Key press handler for inputs
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        if (!isThisPlayerAdmin) {
          handleReady()
        } else if (isThisPlayerAdmin) {
          handleStart()
        }
      }
    }
    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [])

  return (
    <div className="lobby">
      <div className="header">
        <div className="info-container">
          <h3 className="room-info">
            <p>Room: {game.roomCode || '3ASX'}</p>
            <p>Host: {game.host.name}</p>
          </h3>
          <div className="player_info">
            <p className="player_name">{thisPlayer.name}</p>
            <img className="player_img" src={thisPlayer.avatar} />
          </div>
        </div>
      </div>
      <div className="counter-container">
        <div className="counter"></div>
      </div>
      <h1>Players</h1>
      <div className="lobby__players">
        {game?.players?.map((player, i) => (
          <div key={i} className="lobby__player">
            <img src={player.avatar} />
            <p className="lobby__player-name">{player.name}</p>
            <div className="lobby__player-ready">
              {game?.host.name == player.name && (
                <FontAwesomeIcon icon={faCrown} style={{ color: 'yellow' }} />
              )}

              {player.ready && !(game?.host.name == player.name) ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        ))}
      </div>
      {!isThisPlayerAdmin && (
        <button
          className="lobby-button"
          id="lobby-ready"
          onClick={handleReady}
          disabled={isGameStateRoom}
          style={{
            pointerEvents: isGameStateRoom && 'none',
          }}
        >
          {thisPlayer?.ready && !isGameStateRoom && <p>Not Ready</p>}
          {!thisPlayer?.ready && !isGameStateRoom && <p>Ready</p>}
          {isGameStateRoom && <p>...{countdown}...</p>}
        </button>
      )}
      {game?.host.name == thisPlayer.name && (
        <div className="lobby__host-controls">
          <button
            className="lobby-button"
            id="lobby-start"
            onClick={handleStart}
            disabled={isGameStateRoom}
            style={{
              pointerEvents: isGameStateRoom && 'none',
            }}
          >
            {isGameStateRoom ? <p>...{countdown}...</p> : 'Start'}
          </button>
        </div>
      )}
    </div>
  )
}

export default Lobby
