import './styles/App.css'
import { useState, useEffect } from 'react'
import Lobby from './pages/lobby'
import Welcome from './pages/welcome'
import Room from './pages/room'
import Result from './pages/result'

// import websocketurl from './components/welcome'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

function App() {
  const [game, setGame] = useState({ state: 'welcome' })
  const [thisPlayer, setThisPlayer] = useState({
    name: 'Default',
    ready: false,
    alive: false,
  })

  const [ws, setWs] = useState(null)
  const [answer, setAnswer] = useState('')
  const [isGameStateRoom, setIsGameStateRoom] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  //Connect to websocket and listen to messages and errors
  const connectWebSocket = (websocketurl) => {
    const url = websocketurl
    const ws = new WebSocket(url)
    
    console.log('ws connected', ws)

    ws.addEventListener('open', () => {
      console.log('Opened websocket.')
      // setThisPlayer({ ...thisPlayer, alive: true }) // For some reason, it shits this player back to default.
      setWs(ws)
    })


    ws.addEventListener('message', async (msg) => {
      console.log('++++ Incoming ++++ Message ++++ ')
      console.log(await JSON.parse(msg.data))
      let data = await JSON.parse(msg.data)
      //////alert/////
      if (data.event === 'debug') {
        console.log('debug')
      }

      ////////////enteredRoom////////////
      if (data.event === 'enteredRoom') {
        //console.log('enteredRoom event received, roomCode is:', data.roomCode, "username is:", data.username)
        //Sertaç, burda /room/roomCode adresine yönlendirebiliriz.
        //Sertaç, render lobby now.

        data.game.players.forEach((player) => {
          if (player.name === data.username) {
            setThisPlayer({ ...player, alive: true })
          }
        })

        setGame(data.game)
        //find the player with this username, and attach it as this Player
        //Maybe we should NOT send player joined if the data.game.state is room or result.
        ws.send(
          JSON.stringify({
            event: 'playerJoined',
            player: { name: data.username },
          })
        )
        //Acaba, game.state'i değişterek mi yapalım yine, yoksa burda url mi değiştirelim?
      }

      //////////pong////////////////////
      if (data.event === 'pong') {
        //We've moved this listener to the ws useeffect, so no need for now.
        // setThisPlayer({ ...thisPlayer, alive: true })
      }

      ////////////playerJoined////////////
      if (data.event === 'playerJoined') {
        setGame(data.game)
        console.log('data >>>', data)
        toast.success(`Player joined: ${data.player.name}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }

      ////////////playerReady////////////
      if (data.event === 'playerReady') {
        if (data.player === thisPlayer.name) {
          //console.log("This player is.")
          setThisPlayer({ ...thisPlayer, ready: true })
        }

        setGame(data.game)
        //console.log('Updated the game for Ready, now it is: ')
        //console.log(game)
      }

      ////////////playerNotReady////////////
      if (data.event === 'playerNotReady') {
        if (data.player === thisPlayer.name) {
          setThisPlayer({ ...thisPlayer, ready: false })
        }
        setGame(data.game)
      }

      ////////////playerLeft////////////
      if (data.event === 'playerLeft') {
        //Find the data.player in the game.players array and remove it
        //Or just set the game?
        // console.log('playerLeft event received')
        setGame(data.game)
        toast.warn(`Player left: ${data.player}`, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        console.log('data >>>', data)
        //Sertaç pop the user from the array
        // Sertac, update the code to see if this user is the host by checking the players[0], if so, display Host specific actions like Start the game.
      }

      ////////////newRound////////////
      if (data.event === 'newRound') {
        //If this player was afk before, now we can add them in.
        //console.log("newRound event geldiğinde bu oyuncu:")
        //console.log(thisPlayer)
        // if (thisPlayer.state == "waiting") {
        //   //console.log("we got the new round so switching to playing mode")
        //   setThisPlayer({...thisPlayer, state: "playing"})
        // }

        //Sets the isGameStateRoom to true for conditioning lobby and result buttons disable
        setIsGameStateRoom(true)
        toast('Game is starting!', {
          position: 'top-right',
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        // console.log(data.game, isGameStateRoom)
        setTimeout(() => {
          setGame(data.game)
        }, 5000)
      }

      ////////////results////////////
      if (data.event === 'results') {
        //Sertaç, burası eskisinden kalma, tamamen yenileyeceğiz.
        //Her oyuncu cevabını verince, server bu eventi gönderiyor.
        //Bu eventi aldığımızda, game.state'i 'result' olarak geliyo.

        setGame(data.game)
      }

      //if websocket connection fails, show an alert message
      if (data.event === 'connectionFailed') {
        //console.log('connectionFailed event received')
      }
      if (data.event === 'error') {
        //console.log('error event received:')
        //console.log(data.message)
        //sertaç this is where we would have room not found type of stuff. manage those.
      }
    })

    ws.addEventListener('error', (msg) => {
      // WebSocket connection is closed
      //console.log('Bir error gördüm sanki')
      //console.log(msg)
    })

    ws.addEventListener('close', (msg) => {
      // alert("websocket closed")
      //console.log('Websocket kapandı.')
      //console.log(msg)
      // Check if the connection was closed due to a declined request
      setErrorMessage(msg.reason)
      //errorMessage can be 'Room not found' or 'Invalid room code'
      if (msg.reason === 'Room not found') {
        //Sertaç, bu error'u alıp toastlamak lazım önyüzde.
        //console.log('WebSocket connection closed, because:', msg.reason)
      } else if (msg.reason === 'Invalid room code') {
        //console.log('WebSocket connection closed because:', msg.reason)
      } else {
        // WebSocket connection is closed
        //console.log('WebSocket connection closed')
      }
    })

    setWs(ws)
  }

  // Function to send a message using the WebSocket instance
  const sendMessage = (message) => {
    if (ws && ws.readyState === 1) {
      ws.send(JSON.stringify(message))
    }
  }
  //checks if the game is ongoing if not sets the isGameStateRoom
  useEffect(() => {
    if (game.state !== 'room') {
      return setIsGameStateRoom(false)
    }
  }, [game.state])

  // Listen game useEffect
  useEffect(() => {
    console.log('game >>>>>>>>', game)
  }, [game])

  // Listen thisPlayer
  useEffect(() => {
    console.log('thisPlayer >>>>>>>>', thisPlayer)
  }, [thisPlayer])

  // Listen to the ws
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (window.navigator.userAgent.includes('iPhone')) {
        console.log('Visibility state changed:', document.visibilityState)
        if (document.visibilityState === 'hidden') {
          document.title = 'Inactive'
          thisPlayer.alive = false
        }
        if (document.visibilityState === 'visible') {
          document.title = 'Active'
          // console.log("ws", ws);
          // console.log("alive", alive);
          if (ws && ws.readyState === 0) {
            console.log('ws ready state: connecting', ws.readyState)
          } else if (ws && ws.readyState === 1) {
            console.log("we have ws, and it's open. Let's ping to make sure")
            console.log('ws ready state: open', ws.readyState)

            const pingTimeout = 1000 // Timeout duration in milliseconds

            const pingPromise = new Promise((resolve) => {
              const messageHandler = (event) => {
                console.log(JSON.parse(event.data))
                const message = JSON.parse(event.data)
                console.log('message: ', message)
                console.log('message.event: ', message.event)
                if (message.event === 'pong') {
                  toast.success('Received a PONG back!')
                  console.log('alive after 1 second')
                  resolve(true)
                }
              }

              // Add a one-time event listener for the 'message' event
              ws.addEventListener('message', messageHandler)

              // Send the 'ping' message
              sendMessage({ event: 'ping' })

              // Set a timeout to resolve the promise if no 'pong' message received
              setTimeout(() => {
                // Remove the event listener
                ws.removeEventListener('message', messageHandler)
                resolve(false)
              }, pingTimeout)
            })

            pingPromise.then((alive) => {
              if (!alive) {
                console.log('It was not alive, close and open again.')
                ws.close()
                connectWebSocket(ws.url.replace('new', game.roomCode))
              } else {
                console.log('it was alive, all good')
              }
            })
          } else if (ws && ws.readyState === 2) {
            console.log('ws ready state: closing', ws.readyState)
          } else if (ws && ws.readyState === 3) {
            console.log('ws ready state: closed', ws.readyState)
          } else {
            console.log('There might not even be a ws around here!')
          }
        }
      }
    }

    console.log('CHANGED IT CHANGED', ws)
    if (ws) {
      console.log('Ready state:', ws.readyState)
    }

    handleVisibilityChange()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [ws])

  switch (
    game.state //burası useEffect olmadan da dinliyor değişiklikleri.
  ) {
    case 'lobby':
      return (
        <Lobby
          game={game}
          setGame={setGame}
          sendMessage={sendMessage}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          connectWebSocket={connectWebSocket}
          ws={ws}
          setWs={setWs}
          isGameStateRoom={isGameStateRoom}
        />
      )
    case 'room':
      return (
        <Room
          game={game}
          setGame={setGame}
          sendMessage={sendMessage}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          connectWebSocket={connectWebSocket}
          ws={ws}
          setWs={setWs}
          className="room"
        />
      )
    case 'result':
      return (
        <Result
          game={game}
          setGame={setGame}
          sendMessage={sendMessage}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          answer={answer}
          setAnswer={setAnswer}
          isGameStateRoom={isGameStateRoom}
          ws={ws}
          setWs={setWs}
        />
      )

    default:
      return (
        <Welcome
          game={game}
          setGame={setGame}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          connectWebSocket={connectWebSocket}
          ws={ws}
          setWs={setWs}
          errorMessage={errorMessage}
        />
      )
  }
}

export default App
