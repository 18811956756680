import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import WelcomeModal from '../components/welcomeModal'
import '../styles/welcome.css'

const Welcome = ({
  game,
  connectWebSocket,
  thisPlayer,
  setThisPlayer,
  errorMessage,
}) => {
  const [userLoginError, setUserLoginError] = useState('')
  const [roomLoginError, setRoomLoginError] = useState('')
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: {
      username: '',
      roomCode: '',
    },
    mode: 'onChange',
  })

  const formSchema = {
    username: {
      required: {
        value: true,
        message: 'Username missing.',
      },
      maxLength: {
        value: 12,
        message: 'Username max is 12 characters.',
      },
      minLength: {
        value: 4,
        message: 'Username must be a little longer (4+ chars).',
      },
      pattern: {
        value: /^[0-9a-zğüşöçıİĞÜŞÖÇ\s]+$/i,
        message: 'Username only accepts letters and numbers.',
      },
      transform: (value) => value.trim(),
    },
    roomCode: {
      required: {
        value: true,
        message: 'Room code missing',
      },
      maxLength: {
        value: 4,
        message: '4 characters, all we need.',
      },
      minLength: {
        value: 4,
        message: '4 characters, all we need.',
      },

      pattern: {
        value: /^[0-9a-z\s]+$/i,
        message: 'Use only letters and numbers.',
      },
      transform: (value) => value.trim(),
    },
  }

  //Listen to the button click of Devam and Yeni Oda, and if Yeni Oada is clicked then send a websocket request to ws://6n3k.com/room/new (taam -volkan)

  const handleNew = (e) => {
    let username = getValues('username')
    if (!username) {
      setUserLoginError('Enter username')
    } else {
      setThisPlayer({
        ...thisPlayer,
        name: username,
        ready: false,
        alive: false,
      })
      // console.log('thisPlayer is nassı: ' + thisPlayer.name)
      //game.thisPlayer = { name: getValues('username'), ready: false }

      let url
      if (window.location.href == 'https://play.6n3k.com/') {
        url = `wss://whowhomserver.volkan.workers.dev/room/new/${username}`

        // url = `wss://whowhomreact.pages.dev/api/new/${username}`
        console.log("url we're connecting is: ", url)
      } else if (window.location.href == 'http://localhost:3000/') {
        // url = `ws://localhost:8787/room/new/${username}`
        // url = `ws://localhost:8788/api/new/${username}`
        // url = `wss://6n3k.pages.dev/api/new/${username}`
        url = `wss://whowhomserver.volkan.workers.dev/room/new/${username}`
      } else {
        url = `wss://whowhomreact.pages.dev/api/new/${username}`
        console.log('url to websocket is: ', url)
      }
      // let url = `wss://6n3k.pages.dev/api/new/${username}`
      // // url = `wss://6n3kserver.pages.dev/api/new/${username}`
      // url = `wss://6n3k.volkan.workers.dev/room/new/${username}`
      // // url = `ws://localhost:8788/api/new/${username}`
      // console.log(url)
      connectWebSocket(url)
      //setGame({ ...game, state: 'lobby' })
      //websocket(url)
    }
  }
  const handleJoin = (e) => {
    // game.thisPlayer = { name: getValues('username'), ready: false }

    let username = getValues('username')
    let roomCode = getValues('roomCode')

    if (!username) {
      setUserLoginError('Enter username')
    } else if (!roomCode) {
      setRoomLoginError('Enter room code')
    } else {
      setThisPlayer({
        ...thisPlayer,
        name: username,
        ready: false,
        alive: false,
      })

      game.roomCode = getValues('roomCode').toUpperCase().trim()

      //Check if the roomCode matches the 4 digit base36 code.
      let url
      if (window.location.href == 'https://play.6n3k.com/') {
        url = `wss://whowhomserver.volkan.workers.dev/room/${game.roomCode}/${username}`
        // url = `wss://whowhomreact.pages.dev/api/${game.roomCode}/${username}`
      } else if (window.location.href == 'http://localhost:3000/') {
        // url = `ws://localhost:8787/room/${game.roomCode}/${username}`
        // url = `ws://localhost:8788/room/${game.roomCode}/${username}`
        // url = `wss://6n3k.pages.dev/api/${game.roomCode}/${username}`
        url = `wss://whowhomserver.volkan.workers.dev/room/${game.roomCode}/${username}`
      } else {
        url = `wss://whowhomreact.pages.dev/api/${game.roomCode}/${username}`
        console.log('url to websocket is: ', url)
      }

      // // url = `wss://6n3kserver.pages.dev/api/${game.roomCode}/${username}`
      // url = `wss://6n3k.volkan.workers.dev/room/${game.roomCode}/${username}`
      // // url = `ws://localhost:8788/room/${game.roomCode}/${username}`

      connectWebSocket(url)
    }

    //setGame({ ...game, state: 'lobby' })
  }

  //Key press handler for inputs
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        if (e.target.name === 'username') {
          handleNew()
        } else if (e.target.name === 'roomCode') {
          handleJoin()
        }
      }
    }
    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [])

  // Set the username login error message when clicked without input
  useEffect(() => {
    if (errors?.username) {
      setUserLoginError('')
    }
  }, [errors?.username])
  // Set the room login error message when clicked without input

  useEffect(() => {
    if (errors?.roomCode) {
      setRoomLoginError('')
    }
  }, [errors?.roomCode])

  // Reset the form and clear the roomCode input
  useEffect(() => {
    if (errorMessage) {
      reset({ ...getValues(), roomCode: '' })
      setRoomLoginError('Invalid room code')
    }
  }, [errorMessage, reset, getValues])

  return (
    <div className="welcome">
      <div className="welcome__login-container">
        <h1>Welcome!</h1>
        <WelcomeModal tabindex="0"></WelcomeModal>
        <input
          tabindex="1"
          className="welcome__input--login"
          placeholder="Username"
          type="text"
          name="username"
          {...register('username', formSchema.username)}
          style={{
            textAlign: 'center',
            boxSizing: 'border-box',
          }}
        ></input>
        <div className="welcome__login-error-container">
          {(errors?.username || userLoginError) && (
            <p className="welcome__login-error">
              {!errors?.username?.message
                ? userLoginError
                : errors?.username?.message}
            </p>
          )}
        </div>
        <div className="welcome__bottom-container">
          <div className="welcome__bottom-container--left">
            <input
              tabindex="3"
              className="welcome__input--room"
              placeholder="Room Code"
              type="text"
              name="roomCode"
              {...register('roomCode', formSchema.roomCode)}
              style={{
                textAlign: 'center',

                boxSizing: 'border-box',
              }}
            ></input>
            <div className="welcome__login-error-container">
              {(errors?.roomCode || roomLoginError) && (
                <p className="welcome__login-error">
                  {!errors?.roomCode?.message
                    ? roomLoginError
                    : errors?.roomCode?.message}
                </p>
              )}
            </div>
            <div className="welcome__login-button--container">
              <button
                tabindex="4"
                className="welcome__login-button"
                disabled={errors.username || errors.roomCode}
                onClick={handleJoin}
                style={{
                  pointerEvents: (errors.username || errors.roomCode) && 'none',
                }}
              >
                <p>Join</p>
              </button>
            </div>
          </div>
          <div className="welcome__bottom-container--right">
            <button
              tabindex="2"
              className="welcome__login-button"
              disabled={errors.username}
              onClick={handleNew}
              style={{
                pointerEvents: errors.username && 'none',
              }}
            >
              <p>New Room</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
